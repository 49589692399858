<template>
  <div class="my-container flex-col text-center p-2 m-5 copyright-footer"
       style="line-height: 2; z-index: 1">
    <div class="copyright">&copy; {{ year }} | By {{ author }}.</div>
    <div class="footer_custom_text">
      <div><a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow noopener">{{ ipc }}</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    year: Number,
    author: String,
    ipc: String
  }
}
</script>

<style scoped>
.my-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  @apply text-center;
  z-index: 1;
}

.copyright {
  color: #6e6e73;
}

a {
  color: #2997ff;
}
</style>
