<template>
  <div class="container-body bg-fixed">
    <div class="overflow-auto h-full">
      <div style="z-index: 1">
        <Header :msg="msg"/>
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
          <CardView class="m-2"
                    v-for="(site, i) in webSites" :key="i"
                    :index="site.index"
                    :fas="site.fas"
                    :title="site.title"
                    :desc="site.desc"
                    :link="site.link"/>
        </div>
        <Footer author="Holger Zhang" ipc="黑ICP备20001742号" :year="this_year"/>
      </div>

    </div>
  </div>
</template>

<script>
import CardView from "@/components/CardView";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'HelloWorld',
  components: {Footer, Header, CardView},
  props: {
    msg: String
  },
  data: () => {
    return {
      this_year: 2077,
      webSites: [{
        index: '01',
        fas: 'fa-book',
        title: 'Holger 博客',
        desc: '整理知识，记录成长',
        link: '//blog.holgerbest.top/'
      }, {
        index: '02',
        fas: 'fa-atlas',
        title: '导航 StartUP',
        desc: '一个适合作为手机浏览器主页的轻量级导航网站',
        link: '//startup.holgerbest.top/'
      }, {
        index: '03',
        fas: 'fa-robot',
        title: 'FreeGPT',
        desc: '开源GPT工具 (by ramonvc/freegpt-webui)',
        link: '//gpt.holgerbest.top/'
      }, {
        index: '04',
        fas: 'fa-book',
        title: 'API',
        desc: '实验性 API 分发站点',
        link: '//api.live.holgerbest.top/'
      }, {
        index: '05',
        fas: 'fa-warehouse',
        title: '私有存储仓库 Repo',
        desc: '私有存储仓库（图片、PDF存储）',
        link: '//repo.live.holgerbest.top/'
      }, {
        index: '06',
        fas: 'fa-clipboard',
        title: '更多小工具',
        desc: '敬请期待……',
        link: '//live.holgerbest.top/'
      }]
    }
  },
  mounted() {
    this.$data.this_year = new Date().getFullYear()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.container-body {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  height: 100vh;
}

body {
  background: linear-gradient(135deg, #e8ddea, #dbe2f8) !important;
}

body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#fabfbf, #b17ac7);
  clip-path: circle(40% at right 80%);
}

body::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(75deg, #e5edec, #187bcc);
  clip-path: circle(20% at 10% 10%);

}

</style>
