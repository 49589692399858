<template>
  <div id="header"
       class="flex mx-auto justify-content-center place-items-center items-center p-6 text-2xl md:text-4xl"
       style="justify-content: center; align-items: center; color: #188dcc; font-weight: 600">
    <font-awesome-icon class="inline-block align-middle" :icon="['fa', 'cubes']" style="z-index: 1"/>
    <h2 class="flex items-center p-6 text-4xl  header-text-color" style="z-index: 1;">{{ msg }}</h2>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    msg: String
  }
}
</script>

<style scoped>

.header-text-color {
  background: linear-gradient(90deg, #188dcc 20%, #b754ed 80%);
  -webkit-background-clip: text;
  //background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

</style>
